@import '../../../colors';
@import './pieces';

.getPieceWrapper {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    padding: 8px 13px;
    border-radius: 500px;

    background-color: $flowchart-pink;
    border: 1px solid $flowchart-pink-dark;

    @include cursor-for-pieces();
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}