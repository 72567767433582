@import '../../../colors';

.modifyMember {
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: white;
    
    div[class*="inputHolder"] {
        margin-bottom: 20px;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 15px 20px;
        background-color: lighten($grey, 35%);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h2.formHeading {
            color: darken($grey, 10%);
            font-size: 14px;
        }
        
        .cancelButton {
            padding: 5px 30px;
            font-size: 15px;
            color: darken(white, 5%);
            background-color: $sunglo;
            border-radius: 10px;
            box-shadow: none;
            border: none;
            cursor: pointer;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            
            svg {
                width: 12px;
                height: auto;
                margin-right: 5px;
                transform: translateY(1px);
                fill: darken(white, 5%);
            }

            &:active, &:focus {
                outline: none;
            }
        }
    }
}

.viewOnlyMember {
    composes: modifyMember;
    pointer-events: none;

    header {
        pointer-events: all;
    }
}

.errorMessage {
    margin-top: 20px;
    color: $sunglo;
    font-size: 14px;
    text-align: center;
}

.allInputsHolder {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    
    .inputSegment {
        width: calc((100% / 3) - 10px);
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        .label {
            font-size: 10px;
            color: lighten($grey, 5%);
        }
        
        [class="react-datepicker-wrapper"] {
            display: block;
            
            [class="react-datepicker__input-container"] {
                display: block;
                box-shadow: 0px 3px 2px -2px #d5d6d7;
                
                input {
                    display: block;
                    width: 100%;
                    padding: 5px;
                    border: none;
                    box-shadow: none;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    font-weight: 600;
                    color: #283a48;
                    background-color: transparent;
                }
            }
        }
        
        [class="multi-select"] {
            margin-top: 6px;
            box-shadow: 0px 3px 2px -2px lighten($grey, 25%);
            font-size: 14px;
            color: $grey;
            
            [class="dropdown-heading"] {
                border: none !important;
                font-weight: 700;
            }
        }
    }
    
    .phoneSegment {
        width: calc((100% / 3) - 10px);
        display: flex;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 65px;
        }
        
        & > div:last-child {
            width: calc(100% - 65px);
        }
    }
    
    .geoLocationSegment {
        width: calc((100% / 3) - 10px);
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 45%;
        }
        
        & > div:nth-child(2) {
            width: 45%;
        }
        
        & > img:last-child {
            height: 20px;
            margin-top: 14px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}

.buttonHolder {
    text-align: center;
    padding-bottom: 20px;
}

.formButton {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 7px;
    border: none;
    box-shadow: none;
    
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
    height: 30px;
    line-height: 16px;
    
    &:focus, &:active {
        outline: none;
    }
}

.confirmFormButton {
    composes: formButton;
    background-color: $pastel-green;
}