@import '../../colors';

.toggle {
    width: 40px;
    height: 18px;
    border-radius: 8px;
    transition: background-color 0.3s linear;
    cursor: pointer;
    
    .slider {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
        transform: scale(1.1) translateX(19px);
        transition: transform 0.3s linear;
    }
}

.on {
    composes: toggle;
    background-color: #8bc542;
}

.off {
    composes: toggle;
    background-color: #bb4950;
    
    .slider {
        transform: scale(1.1) translateX(0px);
    }
}