@import '../../../colors';
@import '../../../theme';

.normalTable {
    width: 400px;
    margin: 40px 10%;
}

.perMemberTable {
    width: 80%;
    margin: 40px auto;
}