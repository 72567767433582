@import '../../../colors';
@import './pieces';

.constantPiece {
    display: inline-block;
    padding: 8px 13px;
    border-radius: 20px;

    background-color: $flowchart-orange;
    border: 1px solid $flowchart-orange-dark;

    @include cursor-for-pieces();
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}