@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import './colors';

* {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
}

$graph-blue: #9fafff;
$graph-grey: #f2f6fb;
$graph-pink: #ff8ad5;
$graph-light-blue: #95f4ed;

body {
    overflow: hidden;

    .ct-series .ct-bar:nth-child(1), .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
        stroke: $graph-blue;
    }

    .ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie {
        fill: $graph-blue;
    }



    .ct-series .ct-bar:nth-child(2), .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
        stroke: $graph-grey;
    }
    
    .ct-series-b .ct-area, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-slice-pie {
        fill: $graph-grey;
    }



    .ct-series .ct-bar:nth-child(3), .ct-series-c .ct-line, .ct-series-c .ct-point, .ct-series-c .ct-slice-donut {
        stroke: $graph-pink;
    }
    
    .ct-series-c .ct-area, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-slice-pie {
        fill: $graph-pink;
    }



    .ct-series .ct-bar:nth-child(4), .ct-series-d .ct-line, .ct-series-d .ct-point, .ct-series-d .ct-slice-donut {
        stroke: $graph-light-blue;
    }
    
    .ct-series-d .ct-area, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-slice-pie {
        fill: $graph-light-blue;
    }
}

a {
    text-decoration: none;
    color: inherit;
}