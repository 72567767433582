.pageEnter {
    opacity: 0.01;
}

.pageEnter.pageEnterActive {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.pageExit {
    opacity: 1;
}

.pageExit.pageExitActive {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.backgroundColorProvider {
    background-color: #f9fafc;
}