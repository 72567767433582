@import '../../colors';

.SideBar {
    position: fixed;
    left: 0;
    top: 0;
    width: 60px;
    height: 100vh;
    background-color: $blue;
    overflow: hidden;

}

.Logo {
    width: 100%;
    height: 70px;
    background-image: $blue-bottom-gradient;
    display: flex;
    align-items: center;

    img {
        margin: 0 auto;
        display: block;
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}


.NavigationLink {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $lighter-blue;
    justify-content: center;
    align-items: stretch;
    padding: 13px 3px;
    border-bottom: 1px solid transparentize(white, 0.9);
    border-right: 5px solid transparent;
    text-align: center;

    svg {
        width: auto;
        height: 20px;
        margin-bottom: 10px;
        align-self: center;
        fill: $grey;
    }
    
    span {
        display: block;
        font-size: 9px;
        color: $grey;
    }
}

.active {
    border-right: 5px solid $pink;

    svg {
        fill: white;
    }
    
    span {
        color: white;
    }
}

.NavHeader {
    border-bottom: 1px solid rgba($lighter-blue, 0.3);
    font-size: 12px;
    color: $lighter-blue;
    font-weight: 600;
    padding: 17px 0 17px 15px;
}