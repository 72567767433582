@import '../../colors';

.meta {
    position: sticky;
    left: 25px;
    z-index: 2;
    width: calc(100vw - 60px - 350px - 50px);
    margin-top: 7px;
    
    display: flex;
    align-items: center;
    
    svg[class="search-icon"] {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 15px;
        height: auto;
        fill: $pink;
    }
    
    svg[class="filter-icon"] {
        width: 20px;
        height: auto;
        fill: $pink;
        margin-right: 20px;
        cursor: pointer;
    }
    
    .searchBox {
        width: 50%;
        margin-right: auto;
        padding: 10px 35px;
        color: darken($grey, 10%);
        border: 1px solid lighten($grey, 30%);
        font-size: 14px;
        border-radius: 5px;
        
        &::placeholder {
            color: lighten($grey, 10%);
        }
    }
    
    .pageSize {
        margin-right: 15px;
        
        div {
            display: inline-block;
            vertical-align: middle;
            color: lighten($grey, 20%);
            margin-right: 8px;
            font-size: 13px;
            font-weight: bold;
        }
        
        select {
            display: inline-block;
            vertical-align: middle;
            appearance: none;
            
            width: 40px;
            padding: 5px;
            font-size: 13px;
            color: $grey;
            
            font-weight: bold;
            
            border: none;
            background-color: transparent;
        }
        
        svg[class="chevron-arrow-down-icon"] {
            width: 10px;
            height: auto;
            transform: translate(-15px, 2px);
            pointer-events: none;
        }
    }
}

.expandedMeta {
    composes: meta;
    width: calc(100vw - 60px - 50px);
}

.newEntryFormHolder {
    position: absolute;
    top: 0%;
    right: 0px;
    width: 100%;
}
    
.addEntryButton {
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    background-color: $pink;
    border-radius: 15px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

    &:active, &:focus {
        outline: none;
    }
}

.entireTableHolder {
    width: fit-content;
    min-width: 100%;
}

.editEntryFormHolder {
    position: absolute;
    top: 0%;
    right: 0;
    width: calc(100vw - 350px - 60px - 50px);
    min-height: 30px;
    text-align: left;
}

.expandedEditEntryFormHolder {
    composes: editEntryFormHolder;
    width: calc(100vw - 60px - 50px);
}
    
.undoDeleteButton {
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    background-color: $sunglo;
    border-radius: 15px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

    &:active, &:focus {
        outline: none;
    }
}

.selectedAction {
    z-index: 1;
}

.table {
    position: relative;
    width: fit-content;
    min-width: calc(100% - 50px);
    margin: 15px 25px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
    text-align: center;
    
    & > header {
        position: sticky;
        top: 0;
        z-index: 1;
        
        display: flex;
        color: white;
        background-color: $blue;
        
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        
        .cell {
            background-color: $blue;
            padding: 10px 0;
            font-size: 15px;
            flex-grow: 1;
            color: lighten($grey, 20%);
            
            svg {
                width: 14px;
                height: auto;
                transform: translateY(3px);
                fill: $grey;
                
                path:first-child {
                    transform: translateY(-20px);
                }
                
                path:last-child {
                    transform: translateY(20px);
                }
            }
        
            &:first-child {
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
            }
            
            &.actions {
                position: sticky;
                right: 0;
                align-self: stretch;
            }
        }

        .sticky {
            position: sticky;
            z-index: 1;
            align-self: stretch;
        }
        
        .sortable {
            cursor: pointer;
        }
        
        .sortedAsc, .sortedDesc {
            color: white;
            font-weight: bold;
        }
        
        .sortedAsc {
            svg path:first-child {
                fill: white;
            }
        }
        
        .sortedDesc {
            svg path:last-child {
                fill: white;
            }
        }
    }
    
    .tableBody {
        
        .cell {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0;
            font-size: 13px;
            font-weight: bold;
            color: $grey;
            background-color: white;
            flex-grow: 1;
            
            &.actions {
                display: flex;
                position: sticky;
                right: 0;
                align-items: center;
                justify-content: center;
                align-self: stretch;
            }
        }

        .sticky {
            display: flex;
            position: sticky;
            align-items: center;
            justify-content: center;
            align-self: stretch;
        }
        
        .inactiveRow {
    
            .cell {
                color: lighten($grey, 15%);
                background-color: lighten($grey, 35%);
            }
            
        }
        
        .actions {
            padding: 0;
        }
        
        .counter {
            display: inline-block;
            margin-right: 5px;
            color: darken($grey, 10%);
        }
    }
}
        
.row {
    display: flex;
    align-items: stretch;
}

.row:not(:last-child) .cell {
    border-bottom: 1px solid hsl(0, 0%, 90%);
}

.inactiveRow {
    composes: row;
}

.actions {
    position: relative;
    width: 150px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    svg {
        width: 15px;
        height: auto;
        margin: 0 15px;
        fill: $pink;
    }
}

.pageNumbersHolder {
    position: sticky;
    left: 25px;
    margin-bottom: 20px;
    
    display: flex;
    justify-content: flex-end;
    width: calc(100vw - 60px - 350px - 50px);
}

.pageLink {
    width: 30px;
    height: 30px;
    line-height: 30px;
    
    font-size: 14px;
    text-align: center;
    border: 1px solid lighten($grey, 5%);
    color: darken($grey, 20%);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    
    &:last-child {
        margin-right: 0;
    }
}

.pageNavButton {
    width: 30px;
    height: 30px;
    padding: 8px;
    
    img {
        width: 14px;
        height: 14px;
    }
}

.activePageLink {
    composes: pageLink;
    color: white;
    background-color: $pink;
    border: none;
}