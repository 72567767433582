@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
    padding: 0 20px;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: normal;
    font-size: 20px;
    margin: 30px 0 20px;
}

.addButton {
    background-color: var(--contrast-color);
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.widgets {
    display: flex;
    flex-wrap: wrap;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        margin: 20px;
    }
}

.showDataHolder {
    height: 100%;

    section[class*="showDataContainer"] {
        height: 100%;
    }
}