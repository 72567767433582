@import '../../colors';

.tooltipHolder {
    width: 100%;
}

.search {
    padding: 6px;
    background-color: $light-grey;
}

.searchBox {
    display: block;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    height: 24px;
    border: 2px solid transparent;
    box-shadow: none;
    padding: 3px 5px;
    pointer-events: all;

    &:focus, &:active {
        outline: none;
    }
}

.listItems {
    border: 1px solid $light-grey;
    background-color: white;
    font-size: 13px;
}

.listItem {
    border-bottom: 1px solid $light-grey;
}