@import '../../colors';

.Tabs {
    position: sticky;
    left: calc(50% - 250px);
    width: 500px;
    margin: 15px 0 25px;
    display: flex;
    align-self: center;
    background-color: $light-grey;
    border-radius: 40px;

    .Tab {
        display: block;
        border-radius: 40px;
        font-size: 15px;
        letter-spacing: 0.5px;
        font-weight: 600;
        padding: 11px 0;
        min-width: 160px;
        flex-grow: 1;
        text-align: center;
        color: $dark-grey;
        cursor: pointer;

        &.active {
            background-color: $blue;
            color: white;
            pointer-events: none;
        }
    }
}

.TabsWithSidebar {
    composes: Tabs;
    left: calc(50% - 500px);
}