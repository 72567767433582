@import '../../../colors';
@import '../../../theme';

.question {
    position: relative;
}

.disabledQuestion {
    pointer-events: none;
    opacity: 0.4;
}

.questionText {
    font-size: 21px;
    width: 80%;
    margin: 50px auto;
    text-align: center;
}

.requiredIndicator {
    font-size: 16px;
}

.sectionedQuestionText {
    composes: questionText;
    width: 90%;
}

.choicesList {
    width: 45%;
    margin: 0 auto;
}

.sectionedChoicesList {
    composes: choicesList;
    width: 90%;
}

.input {
    display: block;
    width: 45%;
    margin: 0 auto;
    background-color: white;
    color: $dark-grey;
    border-radius: 2px;
    border: 2px solid transparent;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    padding: 15px 20px;
    font-size: 16px;
    text-align: center;

    &:focus, &:active {
        outline: none;
    }
}

.sectionedInput {
    composes: input;
    width: 90%;
}

.locationHolder {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 0 auto;
}

.locationFetch {
    width: 20px;
    height: auto;
}

.sectionedLocationHolder {
    composes: locationHolder;
    width: 90%;
}

.phoneHolder {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 0 auto;

    .input {
        width: 80%;
    }

    div[class*="inputHolder"], div[class*="disabledInputHolder"] {
        width: 17%;
        text-align: center;

        input {
            text-align: center;
        }
    }
}

.sectionedPhoneHolder {
    composes: phoneHolder;
    width: 90%;
}

.dateHolder {
    width: 45%;
    margin: 0 auto;

    [class="react-datepicker-wrapper"] {
        display: block;
        
        [class="react-datepicker__input-container"] {
            display: block;
            background-color: white;
            color: $dark-grey;
            border-radius: 2px;
            border: 2px solid transparent;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
            padding: 10px 20px;
            font-size: 16px;
            
            input {
                display: block;
                width: 100%;
                padding: 5px;
                border: none;
                box-shadow: none;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
                background-color: transparent;
                text-align: center;
            }
        }
    }
}

.sectionedDateHolder {
    composes: dateHolder;
    width: 90%;
}

.errorMessage {
    color: $sunglo;
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
}

.choice {
    background-color: $light-grey;
    color: var(--primary-color);
    padding: 15px 20px;
    margin: 10px 0;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
}

.activeChoice {
    composes: choice;
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
}