@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.reportsHolder {
    display: flex;
    height: 100%;
}

.allReports {
    position: sticky;
    left: 0;
    z-index: 2;
    
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 10px 0px;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.selectedReport {
    position: relative;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    height: 100%;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}

.flowchartHolder {
    width: calc(100% - 50px);
    height: 550px;
    margin: 20px auto;
}