@import '../../../colors';
@import '../../../theme';

.tooltipHolder {
    position: absolute;
    width: 100%;
}

.listHolder {
    position: relative;
}

.listItem {
    padding: 10px;
}