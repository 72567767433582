@import 'colors';

%CommonFocusSpace {
    width: calc(100vw - 60px);
    height: calc(100vh - 70px);
    margin-top: 70px;
    margin-left: 60px;
    overflow: auto;
    position: relative;
    background-color: #f9fafc;
}

%CommonConstrainedFocusSpace {
    @extend %CommonFocusSpace;
    
    width: calc(100vw - 60px - 350px);
}

%CommonErrorMessageHolder {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    padding: 8px 10px;
    padding-right: 100px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    background-color: rgb(31, 31, 31);
    color: white;
    line-height: 1.5;
    cursor: pointer;

    .highlight {
        color: var(--contrast-color);
    }

    .dismissal {
        width: 80px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 13px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $chart-blue;

        svg[class*="cancel-icon"] {
            fill: $chart-blue;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
    }
}

%CommonCardsTree {
    width: calc(100% - 20px);
    margin: 30px auto 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    ul {
        list-style: none;
    }
}

%CommonCheckbox {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid $grey;
    
    &:checked {
        position: relative;
        background-color: $pastel-green;
        border: none;
        
        &::before {
            display: block;
            content: "";
            
            position: absolute;
            top: calc(50% - 1px);
            left: 3px;
            transform-origin: top left;
            transform: rotate(45deg);
            width: 6px;
            height: 1px;
            background-color: white;
        }
        
        &::after {
            display: block;
            content: "";
            
            position: absolute;
            top: 4px;
            right: 4px;
            transform-origin: top right;
            transform: rotate(-50deg);
            width: 11px;
            height: 1px;
            background-color: white;
        }
    }
}

@mixin input-styles($fontSize, $inputPadding) {
    .inputHolder {
        position: relative;
        box-shadow: 0px 3px 2px -2px lighten($grey, 25%);
        margin-top: $fontSize;
        
        .placeholder {
            position: absolute;
            top: $inputPadding;
            left: $inputPadding;
            width: calc(100% - #{2 * $inputPadding});
            font-size: $fontSize;
            letter-spacing: 0.5px;
            font-weight: 600;
            pointer-events: none;
            color: lighten($grey, 5%);
            transform: none;
            transition: font-size 0.25s, transform 0.25s;
                
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        input {
            display: block;
            width: 100%;
            padding: $inputPadding;
            border: none;
            box-shadow: none;
            font-size: $fontSize;
            letter-spacing: 0.5px;
            font-weight: 600;
            color: $light-blue;
            background-color: transparent;
            
            &[readonly] {
                cursor: pointer;
            }
            
            &:active, &:focus {
                outline: none;
            }
            
            &:focus + .placeholder, &.active + .placeholder {
                font-size: #{$fontSize - 4px};
                transform: translateY(#{-6px - $fontSize});
            }
            
            &:focus + .placeholder {
                color: $light-blue;
            }
        }
    }
    
    .disabledInputHolder {
        composes: inputHolder;
    }
        
    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 10px;
        height: 10px;
        pointer-events: none;
    }
    
    .rotated {
        transform: rotate(180deg);
    }
    
    .toggleableIcon {
        composes: icon;
        pointer-events: all;
    }
}