@import '../../../colors';

.usersHolder {
    position: absolute;
    z-index: 3;
    width: 206px;
}

.userEntry {
    padding: 6px;
    cursor: pointer;
}