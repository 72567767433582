@import '../../../colors';

@mixin cursor-for-pieces() {
    cursor: grab;

    div[class*="react-drag-disabled"] & {
        cursor: initial;
    }

    div[class*="react-draggable-dragging"] &, &[class*="react-draggable-dragging"] {
        cursor: grabbing;
    }
}