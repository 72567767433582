@import '../../../colors';
@import './pieces.scss';

.startPiece {
    position: relative;
    width: 120px;
    height: 123px;
    background: url(../../../assets/flowchart/start.svg);
    background-repeat: no-repeat;
    margin-bottom: -12px;

    @include cursor-for-pieces();
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.text {
    position: absolute;
    bottom: 30px;
    left: 10px;
    color: white;
    font-size: 14px;
}