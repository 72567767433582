@import '../../../colors';
@import '../../../theme';

.showDataContainer {
    width: 100%;
    padding: 20px;
    margin: 20px auto 40px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    background-color: white;

    section[class*="table"] {
        width: 100%;
        margin: 0;
    }
}

.showHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.subHeader {
    font-size: 14px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $dark-grey;
    padding-bottom: 15px;
    border-bottom: 4px solid var(--contrast-color);

    .separator {
        width: 6px;
        height: 6px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: $dark-grey;
    }
}

.showHeading {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 600;
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}

.downloadButtonsHolder {
    padding-top: 15px;
    text-align: right;
    margin-top: 20px;
    border-top: 1px solid $light-grey;
    
    button {
        padding: 6px 8px;
        background-color: transparent;
        color: var(--contrast-color);
        border: 1px solid var(--contrast-color);
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;

        svg {
            width: 15px;
            height: 15px;
            fill: $pink;
            stroke: $pink;
            transform: translateY(2px);
            margin-right: 3px;
        }
    }
}

.showModifyButton {
    padding: 8px 15px;
    border-radius: 500px;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
            
    svg {
        margin-top: 6px;
        margin-right: 5px;
        width: auto;
        height: 12px;
        fill: white;
        stroke: white;
    }
}

.updateButton {
    composes: showModifyButton;
    background-color: var(--contrast-color);
    color: white;
}

.deleteButton {
    composes: showModifyButton;
    background-color: $grey;
    color: white;
}

.numberHolder {
    text-align: center;
    padding: 20px;
    font-size: 70px;
    font-weight: bold;
    color: $dark-blue;
    flex-grow: 1;
}

.dataContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.dataFormats {
    max-width: 500px;
    margin: 0 auto 10px;
    display: flex;
}

.format {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    background-color: $light-grey;
    border-bottom: 1px solid white;
    padding: 10px 5px;
    fill: $dark-grey;
    stroke: $dark-grey;
    flex-grow: 1;
    font-size: 11px;
    cursor: pointer;

    svg {
        width: 25px;
        height: 25px;
        margin-bottom: 5px;
    }

    span {
        text-align: center;
    }
}

.normalTable {
    // overflow: auto;
    // max-height: 350px;
}

.activeFormat {
    composes: format;
    background-color: var(--primary-color);
    fill: white;
    stroke: white;
    color: white;
}

.chartContainer {
    width: 100%;
    margin: 30px auto;
}