@import '../../../colors';

.entry {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px;
    border-radius: 3px;
    margin-bottom: 3px;

    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    svg {
        margin: 0 5px;
    }

    svg[class="edit-icon"] {
        width: 15px;
        height: 15px;
    }

    svg[class="trash-icon"] {
        width: 15px;
        height: 15px;
    }

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;
        transform: rotate(-90deg);

        path {
            fill: white;
        }
    }
}

.name {
    flex-basis: 45%;
    color: white;
    font-size: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detail {
    flex-basis: 30%;
    color: rgba(255, 255, 255, 0.6);
    font-size: 11px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    flex-basis: 25%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.indigoThemedEntry {
    composes: entry;
    background-color: $flowchart-indigo;

    svg[class="edit-icon"] {

        path {
            fill: $flowchart-indigo-dark;
        }
    }

    svg[class="trash-icon"] {

        path {
            fill: $flowchart-indigo-dark;
        }
    }
}

.aquaThemedEntry {
    composes: entry;
    background-color: $flowchart-aqua;

    svg[class="edit-icon"] {

        path {
            fill: $flowchart-aqua-dark;
        }
    }

    svg[class="trash-icon"] {

        path {
            fill: $flowchart-aqua-dark;
        }
    }
}

.pinkThemedEntry {
    composes: entry;
    background-color: $flowchart-pink;

    svg[class="edit-icon"] {

        path {
            fill: $flowchart-pink-dark;
        }
    }

    svg[class="trash-icon"] {

        path {
            fill: $flowchart-pink-dark;
        }
    }
}

.camoThemedEntry {
    composes: entry;
    background-color: $flowchart-camo;

    svg[class="edit-icon"] {

        path {
            fill: $flowchart-camo-dark;
        }
    }

    svg[class="trash-icon"] {

        path {
            fill: $flowchart-camo-dark;
        }
    }
}