@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.chatPage {
    display: flex;
    height: calc(100vh - 70px);
}

.chatSelector {
    width: 25%;
    background-color: white;
}

.chatSpace {
    width: 50%;
    background-color: $lighter-grey;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 25px;
}

.chatSummary {
    width: 25%;
    background-color: white;
}

.chatSearch {
    padding: 15px 30px;
    background-color: darken($lighter-grey, 2%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        width: 15px;
        height: 15px;
        fill: $grey;
        margin-right: 10px;
    }

    input {
        background: none;
        border: none;
        box-shadow: none;
        padding: 5px;
        flex-grow: 1;
        font-size: 14px;
        
        &:active, &:focus {
            outline: none;
        }
    }
}

.chatMessage {
    width: 80%;
    padding: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    color: $blue;
    line-height: 1.5;
    border-radius: 15px;
    margin-bottom: 15px;
}

.chatInput {
    position: fixed;
    bottom: 10px;
    width: calc(50% - 70px);
    left: calc(25% + 65px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    padding: 15px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    background-color: white;
}

.receivedMessage {
    composes: chatMessage;
    border-top-left-radius: 0;
}

.sentMessage {
    composes: chatMessage;
    margin-left: 20%;
    border-top-right-radius: 0;
}

.selectedChatDetails {
    width: 70%;
    margin: 30px auto;
}

.selectedChatAvatar {
    width: 200px;
    height: 200px;
    margin: 0 auto 30px;
    border-radius: 50%;
    background-color: $light-grey;
}

.selectedChatName {
    text-align: center;
    margin-bottom: 20px;
    color: $blue;
    font-size: 20px;
    font-weight: bold;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $light-grey;
}