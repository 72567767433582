@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
    padding: 0 20px;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: normal;
    font-size: 20px;
    margin: 30px 0 20px;
}

.cancelButton {
    background-color: $grey;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.errorMessage {
    margin-top: 20px;
    color: $sunglo;
    font-size: 14px;
    text-align: center;
}

.allInputsHolder {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0px;
    
    div[class*="inputHolder"] {
        margin-bottom: 20px;
    }
}
    
.inputSegment {
    width: calc((100% / 3) - 10px);
    
    &:nth-child(3n + 1), &:nth-child(3n + 2) {
        margin-right: 15px;
    }
    
    .label {
        font-size: 10px;
        color: lighten($grey, 5%);
    }
    
    [class="react-datepicker-wrapper"] {
        display: block;
        
        [class="react-datepicker__input-container"] {
            display: block;
            box-shadow: 0px 3px 2px -2px #d5d6d7;
            
            input {
                display: block;
                width: 100%;
                padding: 5px;
                border: none;
                box-shadow: none;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
                color: #283a48;
                background-color: transparent;
            }
        }
    }
    
    [class="multi-select"] {
        margin-top: 6px;
        box-shadow: 0px 3px 2px -2px lighten($grey, 25%);
        font-size: 14px;
        color: $grey;
        
        [class="dropdown-heading"] {
            border: none !important;
            background: transparent !important;
            font-weight: 700;
        }
    }
}

.chartContentConfiguration {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filterContainer {
    width: 70%;
    margin-right: 20px;
}

.visibleFilterContainer {
    composes: filterContainer;
    height: 500px;
}

.customFieldsContainer {
    width: 33%;
}

.customFieldsHeader {
    margin: 10px 0 20px;
    font-size: 17px;
    font-weight: 500;
}

.customFieldOption {
    margin: 15px 0;
}

.buttonsHolder {
    text-align: center;
}

.updateButton {
    background-color: $pastel-green;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.formButton {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 7px;
    border: none;
    box-shadow: none;
    
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
    height: 30px;
    line-height: 16px;
    
    &:focus, &:active {
        outline: none;
    }
}

.confirmFormButton {
    composes: formButton;
    background-color: $pastel-green;
}