@import '../../../colors';
@import './pieces.scss';

.stopPiece {
    display: inline-flex;
    align-items: center;

    position: relative;
    min-width: 137px;
    width: fit-content;
    height: 56px;
    min-width: 120px;
    height: 49px;
    background: url(../../../assets/flowchart/stop.svg);
    background-size: 120px 49px;
    background-repeat: no-repeat;
    padding: 17px 10px;

    @include cursor-for-pieces();

    &::before {
        content: "";
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 0;
        z-index: -1;
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
        border-radius: 4px;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;
}