@import '../../colors';

.option {
    padding: 12px 10px;
    font-size: 13px;
    border-bottom: 1px solid lighten($grey, 25%);
    
    &:hover {
        background-color: $light-blue;
        color: white;
        cursor: pointer;
    }
    
    em {
        font-style: normal;
        color: $pink;
        font-weight: bold;
    }
}

.selectedOption {
    composes: option;
    background-color: $light-blue;
    color: white;
}