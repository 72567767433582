@import '../../colors';
@import '../../theme';

.showHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.showHeading {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 600;
}

.showModifyButton {
    padding: 8px 15px;
    border-radius: 500px;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
            
    svg {
        margin-top: 6px;
        margin-right: 5px;
        width: auto;
        height: 12px;
        fill: white;
        stroke: white;
    }
}

.updateButton {
    composes: showModifyButton;
    background-color: var(--contrast-color);
    color: white;
}

.deleteButton {
    composes: showModifyButton;
    background-color: $grey;
    color: white;
}