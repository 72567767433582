@import '../../../colors';

.modifyVariable {
    padding: 5px;
}

.heading {
    text-align: center;
    padding: 3px;
    color: white;
    margin-bottom: 5px;
}

.typeContainer {
    margin-bottom: 7px;
}

.nameInput {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.buttonsHolder {
    display: flex;
    justify-content: space-between;
}

.button {
    width: calc(50% - 5px);
    padding: 7px;
    text-align: center;
    color: white;
    font-size: 13px;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;

    &:active, &:focus {
        outline: none;
    }
}

.outlineButton {
    composes: button;
    border: 1px solid $flowchart-indigo;
}

.filledButton {
    composes: button;
    background-color: $flowchart-indigo;
}