@import '../../../../colors';
@import '../pieces';

.containerPiece {
    position: relative;
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.innerPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.innerPieceHovering {
    composes: innerPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.upperArmUpperPieceLock, .upperArmLowerPieceLock, .lowerArmUpperPieceLock, .lowerArmLowerPieceLock {
    background-image: url(../../../../assets/flowchart/for.svg);
}

.upperArm {
    position: relative;
    z-index: 3;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 65px;
    padding: 11px 10px 17px;
    margin-bottom: -15px;

    @include cursor-for-pieces();
}

.upperArmContent {
    display: inline-flex;
    align-items: center;
}

.upperArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: top left;
}

.spaceBetweenLocks {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    bottom: 15px;
    width: 60px;

    background-color: $flowchart-aqua;
    border-left: 1px solid $flowchart-aqua-dark;
}

.upperArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: 0 -48px;
}

.extendableUpperArm {
    position: absolute;
    top: 0;
    bottom: 15px;
    left: 58px;
    right: 0;
    z-index: -1;
    border-radius: 4px;
    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-left-color: transparent;
}

.innards {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: stretch;
    min-height: 45px;
}

.leftArm {
    width: 20px;
    min-width: 20px;

    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.innerContent {
    padding-left: 0px;
}

.lowerArm {
    position: relative;
    z-index: 1;
    min-width: 137px;
    width: fit-content;
    min-width: 138px;
    min-height: 45px;
    padding: 11px 10px 17px;
    margin-top: -1px;
    margin-bottom: -10px;

    @include cursor-for-pieces();
}

.lowerArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: 0 -98px;
}

.extendableLowerArm {
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 58px;
    right: 0;
    min-width: 80px;
    z-index: -1;
    border-radius: 4px;
    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-left-color: transparent;
}

.lowerArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: bottom left;
}

.lowerArmContent {
    display: inline-flex;
    align-items: center;
}

.aquaContainerPiece {
    composes: containerPiece;

    & > * > .upperArmUpperPieceLock, & > * > .upperArmLowerPieceLock, & > * > .lowerArmUpperPieceLock, & > * > .lowerArmLowerPieceLock {
        background-image: url(../../../../assets/flowchart/for.svg);
    }

    & > * > .spaceBetweenLocks {
        background-color: $flowchart-aqua;
        border-left: 1px solid $flowchart-aqua-dark;
    }

    & > * > .extendableUpperArm, & > * > .extendableLowerArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
    }

    & > * > .leftArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.camoContainerPiece {
    composes: containerPiece;

    & > * > .upperArmUpperPieceLock, & > * > .upperArmLowerPieceLock, & > * > .lowerArmUpperPieceLock, & > * > .lowerArmLowerPieceLock {
        background-image: url(../../../../assets/flowchart/question.svg);
    }

    & > * > .spaceBetweenLocks {
        background-color: $flowchart-camo;
        border-left: 1px solid $flowchart-camo-dark;
    }

    & > * > .extendableUpperArm, & > * > .extendableLowerArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-left-color: transparent;
    }

    & > * > .leftArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.nextPiece {
    z-index: 0;
}