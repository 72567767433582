@import '../../colors';

.addForm, .modifyForm {
    background-color: $lighter-grey;
    padding: 8px;
    box-shadow: 0px 0px 2px lighten($grey, 25%);
    margin-bottom: 200px;
    
    div[class*="inputHolder"] {
        background-color: white;
        margin-bottom: 20px;
        box-shadow: 0px 0px 2px lighten($grey, 25%);
    }
}

.errorMessage {
    color: $sunglo;
    font-size: 12px;
    text-align: center;
}

.buttonsHolder {
    display: flex;
    justify-content: space-between;
    
    button {
        flex-basis: calc(50% - 5px);
    }
}

.formButton {
    display: block;
    width: 100%;
    padding: 7px;
    border: none;
    margin-top: -8px;
    box-shadow: none;
    
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    height: 30px;
    line-height: 16px;
    
    &:focus, &:active {
        outline: none;
    }
}

.submitFormButton {
    composes: formButton;
    background-color: $blue;
}

.cancelFormButton {
    composes: formButton;
    background-color: $sunglo;
}

.confirmFormButton {
    composes: formButton;
    background-color: $pastel-green;
}