@import '../../../../colors';
@import '../pieces';

.sequenceOperator {
    display: inline-flex;
    align-items: center;
    background-color: $flowchart-orange;
    padding: 5px 15px;
    border-radius: 500px;
    color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);

    @include cursor-for-pieces();
}

.operatorText {
    margin: 0 5px;
}