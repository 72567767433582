@import '../../colors';
@import '../../theme';

.translationOptions {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    padding: 0 20px;
}

.csvOptions {
    display: flex;
}

.fixedButton {
    display: block;
    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 5px 10px;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: var(--contrast-color);
    border: 1px solid var(--contrast-color);
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        fill: $pink;
        stroke: $pink;
        transform: translateY(2px);
        margin-right: 3px;
    }
}

.hiddenFile {
    display: none;
}

.toggleFilter {
    display: flex;
    align-items: center;

    .text {
        margin: 0 15px;
        font-size: 14px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.translationMasterCard {
    width: calc(100% - 50px);
    margin: 20px auto;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.header {
    background-color: lighten($light-grey, 3%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
}

.headerCell {
    padding: 20px;
    color: $dark-grey;
    font-weight: 500;
    font-size: 15px;

    &:first-child {
        flex-basis: 40%;
    }

    &:last-child {
        flex-basis: 60%;
    }
}

.translationRow {
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lighten($grey, 35%);

    &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.originalCell {
    flex-basis: 40%;
    padding: 20px;
    color: $dark-grey;
    font-size: 15px;
}

.translatedCell {
    flex-basis: 60%;
    padding: 20px;
    color: $dark-grey;
    font-size: 15px;
}

.translationInput {
    display: block;
    width: 100%;
    padding: 10px;
    color: darken($grey, 10%);
    background-color: $lighter-grey;
    border: 1px solid lighten($grey, 30%);
    font-size: 15px;
    border-radius: 5px;
    
    &::placeholder {
        color: lighten($grey, 10%);
    }
}