@import '../../../colors';
@import './pieces';

.questionPiece {
    position: relative;
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.visibleItems {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
}

.innerPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.innerPieceHovering {
    composes: innerPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.upperArm {
    position: relative;
    z-index: 3;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 65px;
    padding: 11px 10px 17px;
    margin-bottom: -15px;

    @include cursor-for-pieces();
}

.upperArmContent {
    display: inline-flex;
    align-items: center;
}

.upperArmUpperPieceLock, .upperArmLowerPieceLock, .lowerArmUpperPieceLock, .lowerArmLowerPieceLock {
    background-image: url(../../../assets/flowchart/question.svg);
}

.upperArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 360px 157px;
    background-position: top left;
}

.spaceBetweenLocks {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    bottom: 15px;
    width: 60px;

    background-color: $flowchart-camo;
    border-left: 1px solid $flowchart-camo-dark;
}

.upperArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 360px 157px;
    background-position: 0 -48px;
}

.extendableUpperArm {
    position: absolute;
    top: 0;
    bottom: 15px;
    left: 58px;
    right: 0;
    z-index: -1;
    border-radius: 4px;

    background-color: $flowchart-camo;
    border: 1px solid $flowchart-camo-dark;
    border-left-color: transparent;
}

.innards {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: stretch;
    min-height: 25px;
}

.leftArm {
    width: 20px;
    background-color: $flowchart-camo;
    border: 1px solid $flowchart-camo-dark;
    border-top: none;
    border-bottom: none;
}

.innerContent {
    padding-left: 0px;
}

.lowerArm {
    position: relative;
    z-index: 1;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 55px;
    padding: 11px 10px 17px 70px;
    margin-bottom: -10px;

    @include cursor-for-pieces();
}

.lowerArmContent {
    display: inline-flex;
    align-items: center;
}

.lowerArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 360px 157px;
    background-position: 0 -98px;
}

.extendableLowerArm {
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 58px;
    right: 0;
    z-index: -1;
    border-radius: 4px;

    background-color: $flowchart-camo;
    border: 1px solid $flowchart-camo-dark;
    border-left-color: transparent;
}

.lowerArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 360px 157px;
    background-position: bottom left;
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.booleanIndicator {
    position: relative;
    width: 30px;
    height: 25px;
    margin: 0 10px;
    background-color: darken($flowchart-camo-dark, 5%);

    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-width: 12.5px 10px;
        border-color: transparent;
        border-style: solid;
    }

    &::before {
        left: -20px;
        border-right-color: darken($flowchart-camo-dark, 5%);
    }

    &::after {
        right: -20px;
        border-left-color: darken($flowchart-camo-dark, 5%);
    }
}

.booleanIndicatorHovering {
    composes: booleanIndicator;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

    &::before {
        border-right-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
        border-left-color: rgba(0, 0, 0, 0.4);
    }
}

.nextPiece {
    z-index: 0;
}