@import '../../colors';

.cardsList {
    border-radius: 10px;
    box-shadow: 0px 0px 8px $grey;
    border: 1px solid $light-grey;
    margin: 0 10px;
    min-width: 290px;
    max-height: 100%;
    background-color: white;
    overflow: auto;

    header {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 15px 20px 10px;
        font-size: 16px;
        color: $dark-grey;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;
        height: 45px;
        align-items: center;
        background-color: white;
        justify-content: space-between;
        
        &::after {
            position: absolute;
            top: 100%;
            left: 20px;
            width: calc(100% - 40px);
            height: 3px;
            
            display: block;
            content: "";
            background-color: $pink;
        }
        
        .heading {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        svg {
            width: 15px;
            height: auto;
            margin: 0 5px;
            cursor: pointer;
        }
        
        .counter {
            display: inline-block;
            margin-right: 5px;
            color: darken($grey, 10%);
        }

        button {
            border: 0px;
            height: 30px;
            border-radius: 20px;
            background-color: $light-grey;
            color: $blue;
            font-weight: 600;
            padding: 0 20px;
            letter-spacing: 0.5px;
            font-size: 12px;
            cursor: pointer;
        }
    }
    
    footer {
        position: sticky;
        bottom: 0;
        z-index: 1;
        background-color: white;
        
        padding: 7px 20px 10px;
        
        &::before {
            content: "";
            display: block;
            
            position: absolute;
            top: 0;
            left: 20px;
            width: calc(100% - 40px);
            height: 2px;
            background-color: $pink;
        }
    }
}

.undoDelete {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: 20px;
        margin-left: 10px;
    }
}

.addCard {
    background-color: transparentize($grey, 0.85);
    color: $grey;
    font-size: 12px;
    padding: 7px 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

li[data-react-beautiful-dnd-placeholder] {
    opacity: 0;
}

.editingCardsList {
    composes: cardsList;
    overflow: visible;
}

.addMessage {
    color: $pink;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}

.content {
    padding: 0 20px;
}

.structureList {
    margin-top: 15px;
    list-style: none;
}