@import '../../../../colors';
@import '../pieces';

.stepPiece {
    position: relative;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 59px;
    padding: 11px 10px 17px;
    margin-bottom: -10px;

    @include cursor-for-pieces();
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.visibleItems {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
}

.upperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 145px 59px;
    background-position: top left;
}

.filledUpperPieceLock {
    composes: upperPieceLock;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top-left-radius: 5px;
}

.spaceBetweenLocks {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    bottom: 15px;
    width: 60px;
}

.lowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 145px 59px;
    background-position: bottom left;
}

.filledLowerPieceLock {
    composes: lowerPieceLock;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 5px;
    bottom: 10px;
}

.extendableUpperArm {
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 55px;
    right: 0;
    z-index: -1;
    border-radius: 4px;
}

.indigoStepPiece {
    composes: stepPiece;

    .upperPieceLock, .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/indigo-step.svg);
    }

    .filledUpperPieceLock, .filledLowerPieceLock {
        background: $flowchart-indigo;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-indigo;
        border-left: 1px solid $flowchart-indigo-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-indigo;
        border: 1px solid $flowchart-indigo-dark;
        border-left-color: transparent;
    }
}

.pinkStepPiece {
    composes: stepPiece;

    .upperPieceLock, .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/pink-step.svg);
    }

    .filledUpperPieceLock, .filledLowerPieceLock {
        background: $flowchart-pink;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-pink;
        border-left: 1px solid $flowchart-pink-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-pink;
        border: 1px solid $flowchart-pink-dark;
        border-left-color: transparent;
    }
}

.aquaStepPiece {
    composes: stepPiece;

    .upperPieceLock, .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/aqua-step.svg);
    }

    .filledUpperPieceLock, .filledLowerPieceLock {
        background: $flowchart-aqua;
        border-color: $flowchart-aqua-dark;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-aqua;
        border-left: 1px solid $flowchart-aqua-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
    }
}

.camoStepPiece {
    composes: stepPiece;

    .upperPieceLock, .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/camo-step.svg);
    }

    .filledUpperPieceLock, .filledLowerPieceLock {
        background: $flowchart-camo;
        border-color: $flowchart-camo-dark;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-camo;
        border-left: 1px solid $flowchart-camo-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-left-color: transparent;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}