@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.sectionHolder {
    padding: 20px 40px 40px;
    background-color: rgba(255, 255, 255, 0.6);
    width: 80%;
    margin: 50px auto;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.sectionHeading {
    font-size: 17px;
    font-weight: normal;
    font-style: italic;
    margin: -20px -40px 0;
    color: white;
    width: calc(100% + 80px);
    padding: 20px;
    background-color: var(--primary-color);
}

.processHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.processHeading {
    text-align: center;
    font-size: 40px;
    margin: 15px auto;
    color: var(--primary-color);
    flex-grow: 1;
}

.showDataContainer {
    width: 500px;
    margin: 0 auto;
}

.displayContents {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 160px);
    justify-content: center;
}

.promptText {
    display: flex;
    width: 70%;
    margin: 15px auto;
    font-size: 15px;
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.promptButton {
    display: block;
    width: 250px;
    text-align: center;
    margin: 15px auto;
    background-color: transparent;
    border: 2px solid var(--contrast-color);
    border-radius: 20px;
    padding: 10px 18px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.showTableHolder {
    width: 550px;
    margin: 0 auto;
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}