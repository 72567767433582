@import '../../colors';
@import '../../theme';

.checkboxHolder {
    margin-bottom: 10px;
    cursor: pointer;
    
    input[type="checkbox"] {
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        @extend %CommonCheckbox;
        cursor: pointer;
    }
    
    .text {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: darken($grey, 20%);
        cursor: pointer;
    }
    
}