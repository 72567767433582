@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.languagesHolder {
    display: flex;
    height: 100%;
}

.allLanguages {
    position: sticky;
    left: 0;
    z-index: 2;
    
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 10px 0px;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.translationsArea {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}