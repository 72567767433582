@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.permissionsHolder {
    display: flex;
    height: calc(100% - 80px);
}

.allRoles {
    position: sticky;
    left: 0;
    z-index: 2;
    
    display: block;
    min-width: 330px;
    height: calc(100% + 80px);
    margin-top: -80px;
    padding: 10px 0px;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.locationsHolder {
    display: flex;
    height: 100%;
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}