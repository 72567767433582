@import '../../../colors';
@import '../../../theme';

.memberName {
    font-size: 20px;
    margin: 65px 0 -20px;
    text-align: center;
}

.sectionHolder {
    padding: 20px 40px 40px;
    background-color: rgba(255, 255, 255, 0.4);
    width: 80%;
    margin: 50px auto;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.sectionHeading {
    font-size: 17px;
    font-weight: normal;
    font-style: italic;
    margin: -20px -40px 0;
    color: white;
    width: calc(100% + 80px);
    padding: 20px;
    background-color: var(--primary-color);
}

.showDataContainer {
    width: 500px;
    margin: 0 auto;
}

.sectionContent {
    display: grid;
    grid-gap: 20px;
}

.showMessage {
    display: flex;
    width: 70%;
    margin: 15px auto;
    font-size: 15px;
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    font-size: 20px;
}