@import '../../../colors';

.allInputsHolder {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    
    .inputSegment {
        width: calc((100% / 3) - 10px);
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        .label {
            font-size: 10px;
            color: lighten($grey, 5%);
        }
        
        [class="react-datepicker-wrapper"] {
            display: block;
            
            [class="react-datepicker__input-container"] {
                display: block;
                box-shadow: 0px 3px 2px -2px #d5d6d7;
                
                input {
                    display: block;
                    width: 100%;
                    padding: 5px;
                    border: none;
                    box-shadow: none;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    font-weight: 600;
                    color: #283a48;
                    background-color: transparent;
                }
            }
        }
        
        [class="multi-select"] {
            margin-top: 6px;
            box-shadow: 0px 3px 2px -2px lighten($grey, 25%);
            font-size: 14px;
            color: $grey;
            
            [class="dropdown-heading"] {
                border: none !important;
                font-weight: 700;
            }
        }
    }
    
    .phoneSegment {
        width: calc((100% / 3) - 10px);
        display: flex;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 65px;
        }
        
        & > div:last-child {
            width: calc(100% - 65px);
        }
    }
    
    .geoLocationSegment {
        width: calc((100% / 3) - 10px);
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 45%;
        }
        
        & > div:nth-child(2) {
            width: 45%;
        }
        
        & > img:last-child {
            height: 20px;
            margin-top: 14px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}

.multiSelectHolder {
    margin-bottom: 20px;

    div[class="dropdown-content"] {
        position: relative !important;
    }
        
    [class="multi-select"] {
        margin-top: 6px;
        font-size: 14px;
        color: $grey;
        
        [class="dropdown-heading"] {
            font-weight: 700;
            border-radius: 0 !important;
        }
    }
}

.dateHolder {
    margin-bottom: 20px;
        
    .label {
        font-size: 10px;
        color: lighten($grey, 5%);
    }

    div[class="react-datepicker-popper"] {
        position: relative !important;
        transform: none !important;
    }
        
    [class="react-datepicker-wrapper"] {
        display: block;
        
        [class="react-datepicker__input-container"] {
            display: block;
            box-shadow: 0px 3px 2px -2px #d5d6d7;
            
            input {
                display: block;
                width: 100%;
                padding: 5px;
                border: none;
                box-shadow: none;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
                color: #283a48;
                background-color: transparent;
            }
        }
    }
}