@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.errorMessageHolder {
    @extend %CommonErrorMessageHolder;
}

.locationsHolder {
    display: flex;
    height: calc(100% - 80px);
}

.allProjects {
    position: sticky;
    left: 0;
    z-index: 2;
    
    display: block;
    min-width: 330px;
    height: calc(100% + 80px);
    margin-top: -80px;
    padding: 10px 0px;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.fixedButton {
    position: absolute;

    display: block;
    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 5px 10px;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: var(--contrast-color);
    border: 1px solid var(--contrast-color);
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        fill: $pink;
        stroke: $pink;
        transform: translateY(2px);
        margin-right: 3px;
    }
}

.importTemplate {
    composes: fixedButton;
    top: 20px;
    right: 130px;
}

.hiddenFile {
    display: none;
}

.importButton {
    composes: fixedButton;
    top: 20px;
    right: 20px;
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}