@import '../../../colors';

.tableArea {
    width: calc(100% - 350px);
    overflow: auto;
}

.expandedTableArea {
    composes: tableArea;
    width: 100%;
}

.reportsSection {
    display: flex;
    height: calc(100% - 80px);
}