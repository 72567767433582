@import '../../colors';
@import '../../theme';

.OrganisationProfile {
    @extend %CommonFocusSpace;
    
    h2 {
        width: 95%;
        margin: 20px auto;
        font-weight: 600;
        color: $dark-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;

        button {
            font-size: 15px;
            color: $light-blue;
            font-weight: 500;
            background-color: transparent;
            border: 0px;
        }
    }
}

.errorMessage {
    margin-top: 20px;
    color: $sunglo;
    font-size: 14px;
    text-align: center;
}

.Card {
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    padding: 25px 30px;
    justify-content: space-between;
    flex-wrap: wrap;

    border-radius: 10px;
    box-shadow: 5px 5px 30px -8px $dark-grey;
    border: 1px solid $light-grey;
    width: 95%;
    margin: 0 auto 20px;
    background-color: white;
    
    &:first-child {
        margin-top: 20px;
    }
    
    [class*="disabledInputHolder"] {
        box-shadow: none;
        
        .icon {
            display: none;
        }
    }
    
    .inputSegment {
        width: calc((100% / 3) - 10px);
        margin-bottom: 10px;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
    }
    
    .phoneSegment {
        width: calc((100% / 3) - 10px);
        display: flex;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 65px;
        }
        
        & > div:last-child {
            width: calc(100% - 65px);
        }
    }
    
    .geoLocationSegment {
        width: calc((100% / 3) - 10px);
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &:nth-child(3n + 1), &:nth-child(3n + 2) {
            margin-right: 15px;
        }
        
        & > div:first-child {
            width: 45%;
        }
        
        & > div:nth-child(2) {
            width: 45%;
        }
        
        & > img:last-child {
            height: 20px;
            margin-top: 14px;
            cursor: pointer;
        }
    }
    
    .addressSegment {
        width: calc((200% / 3) - 5px);
        margin-right: 15px;
    }
    
    .nullSegment {
        width: 0px;
    }
}

.buttonHolder {
    text-align: center;
    padding-bottom: 20px;
}

.formButton {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 7px;
    border: none;
    box-shadow: none;
    
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
    height: 30px;
    line-height: 16px;
    
    &:focus, &:active {
        outline: none;
    }
}

.confirmFormButton {
    composes: formButton;
    background-color: $pastel-green;
}


.ProfileImageHolder {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: block;

    &:hover {
        &::before, &::after {
            opacity: 1;
        }
    }

    &::before, &::after {
        transition: opacity 0.3s;
        cursor: pointer;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        border-radius: inherit;
        background-color: transparentize($light-grey, 0.2);
    }

    &::after {
        content: '';
        background-image: url('./uploading-archive.svg');
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
        width: 30px;
        height: 30px;
        opacity: 0;
        background-size: contain;
        transform: scale(0.7);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    input {
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: -1;
        width: 0px;
        height: 0px;
    }
}

.ProfileDataHolder {
    width: calc(100% - 170px);
    margin-left: auto;
    align-self: center;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 30px;
}

.ContentFragment {
    width: 33%;
    margin-bottom: 20px;

    label {
        position: relative;
        picture {
            width: 15px;
            vertical-align: middle;
            margin-right: 7px;
            opacity: 0.7;
            display: inline-block;
            img {
                width: 100%;
                height: 100%;
            }
        }
        font-size: 13px;
        font-weight: 600;
        color: $dark-grey;
    }

    .InputValue {
        color: $grey;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        line-height: 1.5;
    }
}

.Address {
    composes: ContentFragment;
    width: 50%;

    .InputValue {
        width: 80%;
    }
}

.ColorsHolder {
    display: flex;
    width: 100%;
    justify-content: space-between;

    label {
        display: block;
        position: relative;
        font-size: 13px;
        font-weight: 600;
        color: $dark-grey;
        margin-bottom: 15px;
    }
}

.editIcon {
    position: absolute;
    right: 30px;
    top: 20px;
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
        width: 10px;
        height: 10px;
    }
}

.OrganisationHeader {
    background-image: $blue-bottom-gradient;
    display: flex;

    select {
        appearance: none;
    }
    
    .orgBox {
        width: 50%;
        display: flex;
        padding: 20px 0;

        input[type=file] {
            display: none;
        }

        &.edittable {
            z-index: 2;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: $blue-bottom-gradient;
            }

            label[for=org-profile-img] {
                cursor: pointer;
            }

            label[for=org-profile-img],
            .orgName {
                z-index: 2;
                position: relative;
            }

            .details .flexInput {
                border-bottom: 1px solid $blue;
                width: 100%;
                padding-bottom: 2px;

                img {
                    display: block;
                }
            }
        }

        label[for=org-profile-img] {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $light-grey;
            margin-left: 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .orgName {
            border: none;
            box-shadow: none;
            background-color: transparent;
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.3);
            
            &:disabled {
                border: none;
            }
        }

        .details {
            margin-left: 20px;
            color: $light-grey;

            .orgName {
                font-size: 14px;
                margin-bottom: 2px;
            }

            .orgCodeInput {
                margin-left: 5px;
            }

            .orgCodeInput,
            .orgCategory,
            label {
                font-size: 12px;
                margin-top: 4px;
                background-color: transparent;
                color: $light-grey;
                border: none;
            }
        }

        .orgCategory {
            width: 100%;
        }

        .flexInput {
            display: flex;
            position: relative;

            img {
                width: 8px;
                margin-right: 5px;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                cursor: pointer;
                display: none;
            }
        }

        .actionButtons {
            position: absolute;
            right: 20px;
            top: 20px;

            button {
                padding: 5px 25px;
                border-radius: 20px;
                border: none;
                color: white;
                font-size: 12px;
                cursor: pointer;
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin: 0 4px;

                img {
                    width: 8px;
                    margin-right: 5px;
                }
                
                &:first-child {
                    background-color: $pink;
                }
            }
        }

        .editIcon {
            background-color: $blue;
        }

        &:nth-of-type(1) {
            border-right: 1px solid $grey;
            position: relative;
        }

        &:nth-of-type(2) {
            align-items: center;
            justify-content: flex-end;
        }

        .label {
            color: $light-grey;
            font-size: 14px;
        }

        .plan {
            margin: 0 30px 0 20px;
            padding: 10px 15px;
            background-color: $lighter-grey;
            border-radius: 5px;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.contentHolder {
    display: flex;
}

.orgDetails {
    flex-grow: 1;
}

.upgradePlan {
    display: none;
    width: 300px;

    .adText {
        background-color: $light-grey;
        text-align: center;
        padding: 12px 0;
        font-size: 12px;
        color: $grey;
        font-weight: 600;
    }

    .planText {
        background-image: $blue-bottom-gradient;
        display: flex;
        justify-content: center;
        padding: 12px 0;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            color: white;
            font-weight: 300;
        }
    }

    ul {
        background-color: white;
        padding: 10px 0;

        li {
            list-style: none;
            text-align: center;
            padding: 20px 0;
            color: $grey;
            font-size: 14px;

            span {
                color: $pink;
            }
        }
    }

    .bottomContent {
        background-image: $blue-bottom-gradient;
        padding: 20px 0;

        button {
            display: block;
            padding: 10px 15px;
            background-color: $pink;
            margin: 0 auto;
            border: none;
            border-radius: 8px;
            color: white;
            font-size: 14px;
        }

        a {
            display: block;
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            color: $light-grey;
            text-decoration: underline;
            font-weight: 100;
        }
    }
}