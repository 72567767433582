@import '../../colors';

.listItem {
    padding-bottom: 15px;
    list-style: none;
    
    &:last-child {
        margin-bottom: 0;
    }
}

.normal {
    border-radius: 10px;
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 50px;
    background-color: $light-grey;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 0px 0px 1px 0px lighten($grey, 20%);
    margin-bottom: 15px;

    [data-react-beautiful-dnd-draggable] & {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0px 0px 6px 1px lighten($grey, 5%);
    }

    .index {
        font-weight: 700;
        min-width: 50px;
        text-align: center;
        color: darken($grey, 10%);
        font-size: 14px;
    }

    .details {
        flex-grow: 1;
        background-color: lighten($light-grey, 5%);
        font-weight: 500;
        border-radius: 10px;
        letter-spacing: 0.5px;
        overflow: hidden;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
        .title {
            width: 100%;
            padding-bottom: 3px;
            padding-left: 20px;
            padding-right: 20px;
            color: $dark-grey;
            font-size: 13px;
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        .subTitle {
            width: 100%;
            border-top: 1px solid lighten($light-grey, 3%);
            padding-top: 3px;
            padding-left: 20px;
            padding-right: 20px;
            color: $grey;
            font-size: 11px;
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    .modify {
        width: 35px;
        height: 50px;
        display: none;
        background-color: $lighter-grey;
        font-weight: 500;
        border-radius: 10px;
        
        .edit, .delete {
            line-height: 25px;
            font-size: 11px;
            font-weight: bold;
            text-align: center;
            border-top: 1px solid $dark-grey;
            cursor: pointer;
            
            &:first-child {
                border-top: 0;
            }
            
            svg {
                margin-top: 6px;
                width: auto;
                height: 12px;
            }
        }
        
        .edit {
            height: 50%;
            line-height: 25px;
            
            path {
                fill: $light-grey;
            }
        }
        
        .delete {
            height: 50%;
            line-height: 25px;
            
            path {
                fill: $sunglo;
            }
        }
    }
    
    .toggleHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 50px;
        background-color: transparent;
    }
    
    .triToggleHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 60px;
        height: 50px;
        background-color: transparent;
        padding-bottom: 10px;
    }

    .legend {
        margin: 5px 3px;
        font-size: 9px;
        color: $dark-grey;
    }
    
    .iconHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 50px;
        background-color: transparent;
        color: darken($grey, 10%);

        svg {
            width: auto;
            height: 20px;
            fill: $grey;
        }
    }
}

.active {
    composes: normal;
    background-color: $pink;

    .index {
        color: white;
    }

    .details {
        background-color: $blue;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        
        .title {
            color: white;
        }
        
        .subTitle {
            color: $grey;
            border-color: $dark-grey;
        }
    }
    
    .modify {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $blue;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px solid $dark-grey;
    }
}

.deleting {
    composes: normal;
    background-color: $light-grey;
    opacity: 0.6;
    
    .index {
        display: none;
    }
        
    .title {
        width: 100%;
        padding-bottom: 3px;
        color: lighten($grey, 10%);
        font-size: 13px;
        text-align: center;
        
        svg {
            width: 15px;
            height: auto;
            margin-left: 5px;
            transform: translateY(3px);
            fill: $grey;
        }
    }
}