@import '../../colors';

.optionsList {
    position: absolute;
    top: calc(100% + 2px);
    left: 0px;
    right: 0px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 4px lighten($grey, 25%);
    
    max-height: 200px;
    overflow: auto;
    transition: visibility 0s linear 0s;
}

.hiddenOptionsList {
    composes: optionsList;
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    
    
    /******************************************************************************************
     * Delay the options hiding by a couple of seconds.                                       *
     *                                                                                        *
     * The blur event runs before the click event is registered on the <Option> component, so *
     * the element is removed from the DOM before it even runs. Since that happens, the       *
     * onSelect function doesn't even get a chance to run. We need to delay the hiding of the *
     * options on blur to avoid that.                                                         *
     ******************************************************************************************/
    transition: visibility 0s linear 1s;
}