@import '../../../colors';
@import '../../../theme';

.cardsTree {
    @extend %CommonCardsTree;
    height: calc(100% - 131px);
}

.newVerticalHolder {
    min-width: 290px;
}

.newCustomFieldPrompt {
    background-color: transparentize($grey, 0.85);
    color: $grey;
    font-size: 14px;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
}