@import '../../colors';
@import '../../theme';

.contact {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    cursor: pointer;
}

.avatarHolder {
    position: relative;
    margin-right: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: silver;
}

.selectedContact {
    composes: contact;
    background-color: $light-grey;
}

.textHolder {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 500;
}

.textRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.name {
    color: $blue;
    font-size: 14px;
}

.time {
    color: $grey;
}

.activeTime {
    composes: time;
    color: var(--contrast-color);
    font-weight: bold;
}

.lastMessage {
    color: $grey;
}

.unReadMessages {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    background-color: var(--contrast-color);
    color: white;
    margin-right: 10px;
}