:root {
    --primary-color: #1d2653;
    --contrast-color: #ed0c6e;
    --primary-gradient: linear-gradient(to bottom, rgba(25,39,80,1) 0%, rgba(50,77,159,1) 100%);
    --primary-reverse-gradient: linear-gradient(to top, rgba(25,39,80,1) 0%, rgba(50,77,159,1) 100%);
}

$blue: var(--primary-color);
$blue-bottom-gradient: var(--primary-gradient);
$blue-top-gradient: var(--primary-reverse-gradient);
$porcelain: #ebeced;

$lighter-blue: #727f88;
$dark-blue: #1a2430;
$light-blue: #283a48;
$blue-grey: #717f8a;
$grey: #949599;
$dark-grey: #595959;
$light-grey: #e7e7e7;
$lighter-grey: #f9fafc;
$pink: var(--contrast-color);

$pastel-green: #8ed15f;
$sunglo: #e5646d;
$dirty-yellow: #a79405;

$chart-blue: #aae0ed;
$chart-red: #f68ebd;
$chart-yellow: #fdd89f;

$flowchart-green: #b6da76;
$flowchart-aqua: #14b1ab;
$flowchart-orange: #efaa4b;
$flowchart-indigo: #8891c8;
$flowchart-pink: #d289c0;
$flowchart-camo: #889e46;

$flowchart-green-dark: #99b262;
$flowchart-aqua-dark: #13998f;
$flowchart-orange-dark: #cc8d42;
$flowchart-indigo-dark: #6a7199;
$flowchart-pink-dark: #b879ac;
$flowchart-camo-dark: #59871e;