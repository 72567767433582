@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

$grid-line-color: #ddd;

.normalFlowchartHolder {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1px 1px 280px 350px;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, $grid-line-color 1px, transparent 1px), linear-gradient(to bottom, $grid-line-color 1px, transparent 1px);
}

.waitingForDragFlowchartHolder {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1px;
    padding: 1px 1px 280px 350px;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, $grid-line-color 1px, transparent 1px), linear-gradient(to bottom, $grid-line-color 1px, transparent 1px);
    pointer-events: none;

    [class*="attachment-target"] {
        pointer-events: all;
    }
}

.piecesCollection {
    position: fixed;
    left: 450px;
    width: 270px;
    margin-top: 20px;
    max-height: calc(100vh - 200px);
    overflow: auto;
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.searchSection {
    padding: 15px 20px;
    border-bottom: 1px solid silver;
}

.searchInput {
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: none;
    font-size: 14px;
    color: $grey;
    padding: 10px;
}

.collectionHeader {
    background-color: var(--primary-color);
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 20px;
    line-height: 1.5;
}

.categoryHeading {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid silver;
    cursor: pointer;
}

.pieceEntry {
    padding: 15px 25px;
    color: grey;
    font-size: 14px;
    border-bottom: 1px solid silver;
    cursor: pointer;
}

.categoryIndicator {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.categoryName {
    color: grey;
    font-size: 14px;
    flex-grow: 1;
}

.categoryChevron {

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;

        path {
            fill: grey;
        }
    }
}

.expandedCategoryChevron {

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;
        transform: rotate(180deg);

        path {
            fill: grey;
        }
    }
}

.pieceHolder {
    position: absolute;
    top: 0;
    left: 0;

    [class*="normalFlowchartHolder"] & {
        pointer-events: none;

        & > * {
            pointer-events: all;
        }
    }
}