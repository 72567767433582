@import '../../colors';

.PageHeader {
    position: fixed;
    left: 60px;
    top: 0;
    z-index: 3;
    width: calc(100% - 60px);
    height: 70px;
    background-color: $porcelain;
    display: flex;
    align-items: stretch;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
}

.SearchBar {
    position: relative;
    align-self: center;
    width: 50%;
    margin-right: auto;

    img {
        margin-left: 40px;
        width: 20px;
        vertical-align: middle;
        -webkit-filter: brightness(50%);
        filter: brightness(50%);
    }

    input {
        height: 100%;
        width: 80%;
        margin-left: 30px;
        height: 70px;
        background-color: transparent;
        border: 0px;
        color: $grey;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
}

.pushDataButton {
    background-color: $pink;
    color: white;
    align-self: center;
    padding: 12px 20px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.results {
    position: absolute;
    z-index: 1;
    background: white;
    width: 90%;
    margin-left: 10%;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    
    header {
        background-color: lighten($grey, 30%);
        padding: 5px 15px;
        color: darken($grey, 10%);
        font-size: 14px;
    }
}

.namedResult {
    display: flex;
    background-color: lighten($grey, 22%);
    padding: 7px 15px;
    color: darken($grey, 30%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    
    &:hover {
        background-color: $blue;
        color: white;
    }
    
    &:last-child {
        border-bottom: none;
    }
    
    em {
        font-style: normal;
        color: $pink;
        font-weight: bold;
    }
}

.personResult {
    display: flex;
    align-items: center;
    background-color: lighten($grey, 22%);
    padding: 7px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    
    .personName {
        color: darken($grey, 30%);
        margin-right: 10px;
    }
    
    .personId {
        color: darken($grey, 25%);
        font-size: 14px;
    }
    
    .personNumber {
        flex-grow: 1;
        color: darken($grey, 15%);
        font-size: 13px;
        text-align: right;
    }
    
    &:hover {
        background-color: $blue;
    
        .personName {
            color: white;
        }

        .personId {
            color: darken(white, 5%);
        }

        .personNumber {
            color: darken(white, 15%);
        }
    }
    
    &:last-child {
        border-bottom: none;
    }
    
    em {
        font-style: normal;
        color: $pink;
        font-weight: bold;
    }
}

.nameHolder {
    display: flex;
    height: 70px;
    cursor: pointer;
}

.buttonHolder {
    display: flex;
    align-items: center;
    margin: 0 20px;
}

.help {
    width: 50px;
    font-size: 26px;
    line-height: 70px;
    font-weight: bold;
    text-align: center;
    color: $pink;
}

.dropdownHolder {
    position: absolute;
    top: 70px;
    font-size: 12px;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid $grey;
    border-top: none;
    
}
    
.dropdownOption {
    
    display: flex;
    align-items: center;
    width: 150px;
    padding: 8px 15px;
    
    background-color: lighten($grey, 32%);
    color: $grey;
    border-bottom: 1px solid $grey;
    
    &:first-child {
        box-shadow: inset 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
    }
    
    &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: none;
    }
    
    svg {
        width: 15px;
        margin-right: 10px;
        fill: $grey;
    }
}

.stackActions {
    width: 10%;
    display: flex;
    line-height: 70px;
    
    div {
        width: 50%;
        cursor: pointer;
    }
}

.ProfileUtilitiesHolder {
    align-self: center;
    display: flex;
    font-weight: 500;
    letter-spacing: 0.5px;
    align-items: stretch;

    .name {
        color: darken($grey, 15%);
        font-size: 15px;
        margin-right: 15px;
        align-self: center;
        display: block;
    }

    .nameRepresentation {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: rgba($grey, 0.7);
        color: white;
        font-size: 16px;
        font-weight: bold;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    .icon {
        display: flex;
        width: 80px;
        height: 70px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &.active {
            &::before {
                content: '';
                top: 37px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                left: 43px;
                background-color: $pink;
                position: absolute;
                z-index: 1;
            }
        }
    
        &.bell {
            box-shadow: 1px 0px 10px 0px transparentize($grey, 0.5);

            img {
                transform: rotate(45deg);
                -webkit-filter: brightness(80%);
                filter: brightness(80%);
            }
        }

        &.logout {
            img {
                transform: rotate(180deg);
            }
        }

        img {
            display: block;
            height: 25px;
        }
    }
}