@import '../../../colors';
@import '../../../theme';

.transfer {
    font-size: 21px;
    width: 80%;
    margin: 50px auto;
    text-align: center;
}

.choicesList {
    width: 45%;
    margin: 0 auto;
}

.input {
    display: block;
    width: 45%;
    margin: 0 auto;
    background-color: white;
    color: $dark-grey;
    border-radius: 2px;
    border: 2px solid transparent;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    padding: 15px 20px;
    font-size: 16px;
    text-align: center;

    &:focus, &:active {
        outline: none;
    }
}

.errorMessage {
    color: $sunglo;
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
}

.choice {
    background-color: $light-grey;
    color: var(--primary-color);
    padding: 15px 20px;
    margin: 10px 0;
    cursor: pointer;
    text-align: center;
}

.text {
    font-size: 16px;
    margin-bottom: 5px;
}

.subText {
    font-size: 12px;
}

.activeChoice {
    composes: choice;
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}